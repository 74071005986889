<template>
  <div class="contact-form">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div
          class="col-md-3 d-flex justify-content-center logo-form_logo-wrapper"
        >
          <div class="logo-form-canvas">
            <img
              class="logo-form"
              :src="require('@/assets/svg/logo-smaterials.svg')"
              alt=""
            />
          </div>
        </div>

        <b-col cols="12" md="5">
          <h2 class="form_block__one_title">
            ЧТОБЫ СТАТЬ ПАРТНЕРОМ, ОСТАВЬТЕ ЗАЯВКУ
          </h2>

          <h2 class="form-block second-title">
            Если вы ИП или юридическое лицо и хотите кратно вырасти, работая на
            рынке тендеров без рисков.
            <span class="bronze">
              Заполните поля формы и отправьте заявку.
            </span>
          </h2>

          <p class="text_small">
            После мы свяжемся с вами и проведем подробную консультацию, на
            которой расскажем условия работы, подберем подходящий для вас вид
            сотрудничества и наметим первые шаги развития вашей компании на
            рынке тендеров.
          </p>
        </b-col>

        <b-col cols="12" md="4">
          <b-form @submit="sendForm">
            <input
              v-model="form.name"
              id="name"
              class="main-input"
              type="text"
              placeholder="Введите ваше имя"
              required
            />

            <input
              v-model="form.phone"
              id="phone"
              class="main-input"
              type="text"
              placeholder="Введите Ваш мобильный телефон"
              required
            />

            <b-row align-h="center">
              <button type="submit" id="button_outline" :disabled="disabled">
                {{ buttonText }}
              </button>
            </b-row>
          </b-form>
        </b-col>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PartnerForm",
  data() {
    return {
      form: {
        name: null,
        phone: null,
        title: 'Форма "Стать партнером"',
      },

      disabled: false,
      buttonText: "Отправить",
    };
  },
  methods: {
    async sendForm(ev) {
      ev.preventDefault();

      this.disabled = true;

      try {
        await this.$store.dispatch("sendForm", this.form);

        this.buttonText = "Отправлено";
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
/* form-block */
.contact-form {
  background-color: $gray;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 100px;
  position: relative;
  z-index: 2;
}
.form-block.second-title {
  color: $white;
  font-size: 18px;
  margin: 0;
  padding-bottom: 15px;
}
.form_block__one_title {
  color: $white;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding-bottom: 15px;
}
.bronze {
  color: $accent;
}
.logo-form-canvas {
  width: 140px;
  height: 140px;
  position: relative;
}
.logo-form {
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: contain;
}
.logo-form_logo-wrapper {
  display: flex;
  justify-content: flex-start;
}
</style>
