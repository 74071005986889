<template>
  <div class="cases">
    <b-container>
      <b-row>
        <div v-swiper:mySwiper="swiperOption">
          <div class="swiper-wrapper case__swiper_wrapper">
            <div
              v-for="(item, index) in cases"
              :key="index"
              class="swiper-slide"
            >
              <div class="case__swiper__card_wrapper">
                <div class="case__swiper__card">
                  <h2>{{ item.title }}</h2>

                  <p>{{ item.text }}</p>

                  <p class="case__swiper__price">{{ item.price }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="swiper-pagination"></div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "PartnerCases",
  swiperOption: {
    autoHeight: true,
    cssMode: true,
    mousewheel: true,
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 3,
        spaceBetween: 15,
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 3,
        spaceBetween: 15,
      },
    },

    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
  },
  data() {
    return {
      swiperOption: {
        cssMode: true,
        autoHeight: true,
        mousewheel: true,
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 4,
            spaceBetween: 15,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 4,
            spaceBetween: 15,
          },
        },

        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      },
      cases: [
        {
          title: "Кейс 1",
          text: "Наша экспертность и надежность - мы знаем как работать с крупными заказчиками, наш опыт исчисляется 10 лет и 10 миллиардов реализованных контрактов.",
          price: "5 000 000 ₽",
        },
        {
          title: "Кейс 2",
          text: "Наша экспертность и надежность - мы знаем как работать с крупными заказчиками, наш опыт исчисляется 10 лет и 10 миллиардов реализованных контрактов.",
          price: "5 000 000 ₽",
        },
        {
          title: "Кейс 3",
          text: "Наша экспертность и надежность - мы знаем как работать с крупными заказчиками, наш опыт исчисляется 10 лет и 10 миллиардов реализованных контрактов.",
          price: "5 000 000 ₽",
        },
        {
          title: "Кейс 4",
          text: "Наша экспертность и надежность - мы знаем как работать с крупными заказчиками, наш опыт исчисляется 10 лет и 10 миллиардов реализованных контрактов.",
          price: "5 000 000 ₽",
        },
        {
          title: "Кейс 5",
          text: "Наша экспертность и надежность - мы знаем как работать с крупными заказчиками, наш опыт исчисляется 10 лет и 10 миллиардов реализованных контрактов.",
          price: "5 000 000 ₽",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.cases {
  padding-bottom: 100px;
}
.case__swiper_wrapper {
  margin-bottom: 30px;
}
.case__swiper__card {
  padding: 30px;
}
.case__swiper__card_wrapper {
  display: flex;
  justify-content: center;
  background-color: $gray;
}
.case__swiper__price {
  color: $accent;
  font-weight: 500;
  font-size: 20px;
}
</style>
