<template>
  <div class="partner-profit">
    <b-container>
      <b-row align-h="center">
        <div v-for="(item, index) in profits" :key="index" class="col-md-4">
          <div class="partner-profit__card">
            <b-row align-h="center">
              <div class="partner-profit__img-wrapper">
                <img
                  class="partner-profit__img"
                  :src="require('@/assets/svg/' + item.img)"
                />
              </div>
            </b-row>

            <b-row align-h="center">
              <h3 class="partner-profit__title">{{ item.title }}</h3>

              <p class="partner-profit__text">{{ item.text }}</p>
            </b-row>
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "PartnerProfits",
  data() {
    return {
      profits: [
        {
          img: "icon-1.svg",
          title: "Профессионализм",
          text: `
          Знаем, как работать с крупными заказчиками. Члены нашей команды 10 лет в тендерах, и за это время реализованы контракты на сумму более 10 миллиардов рублей. Постоянно работаем над укреплением отношений с заказчиками.
          `,
        },
        {
          img: "icon-2.svg",
          title: "Рост",
          text: `
          Выводим на рынок крупных сделок. Помогаем быстро и безболезненно перейти от маленьких чеков к крупным.
          `,
        },
        {
          img: "icon-3.svg",
          title: "Безопасность",
          text: `
          Сотрудничество с нами исключает риск неисполнения контракта из-за отсутствия нужного объема финансирования или работу с недобросовестным заказчиком.
          `,
        },
        {
          img: "icon-4.svg",
          title: "Поддержка",
          text: `
          Добиваемся эффективности и соответствия требованиям заказчика. Товары и услуги наших партнёров становятся востребованными на рынке тендеров.
          `,
        },
        {
          img: "icon-5.svg",
          title: "Оптимизация",
          text: `
          Избавляем от непрофильных задач. Берём на себя переговоры с заказчиками, подготовку необходимых документов и другие процессы, не относящиеся к профильному виду деятельности клиента. 
          `,
        },
        {
          img: "icon-6.svg",
          title: "Анализ",
          text: `
          Мы ведём строгий учёт средств, проверяем финансовую часть на стадии подачи заявки, чтобы исключить экономические риски.
          `,
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.partner-profit {
  padding-top: 30px;
  padding-bottom: 100px;
}

.partner-profit__img-wrapper {
  height: 120px;
  width: 120px;
  position: relative;
  overflow: hidden;

  margin-bottom: 30px;
}
.partner-profit__img {
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: contain;
}

.partner-profit__title {
  text-align: center;
  margin-bottom: 15px;
}
.partner-profit__text {
  text-align: center;
}

.partner-profit__card {
  padding: 0 30px 30px 30px;
}
</style>
