<template>
  <div>
    <!-- Header -->
    <div class="applicant-header">
      <img
        class="applicant-header_bg"
        :src="require('@/assets/photo/_S__3094.jpg')"
        alt=""
      />

      <div class="applicant-header_bg-filter"></div>

      <b-container>
        <b-row
          align-h="center"
          align-v="center"
          class="applicant-header_content"
        >
          <b-col cols="12" md="8">
            <h1 class="applicant-header_title partner_title_page header_title">
              Раскрываем возможности рынка тендеров компаниям, нацеленным на
              рост
            </h1>

            <p class="text_one">
              С нами легко стать поставщиком на рынке тендерных закупок.<br />
              С 2020 года нашими партнёрами стали 14 компаний. Их годовой оборот
              вырос в среднем в 5 раз.
            </p>
            <!-- <p class="text_small text-center">
              С-МАТЕРИАЛС - ваша возможность роста в партнерстве
            </p> -->
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!-- Welcome -->
    <div class="ac-welcome">
      <img class="rictangle__ac" src="@/assets/img/rictangle.png" alt="" />

      <WelcomeHome :slides="slides" />
    </div>

    <LineInfo
      title="Комплексные поставки для крупного бизнеса"
      description="С-МАТЕРИАЛС - возможности для роста в партнерстве"
    />

    <!-- Features -->
    <div class="features">
      <b-container>
        <b-row class="left">
          <b-col cols="12" md="6">
            <div class="features_img-canvas">
              <img
                class="features_img"
                :src="require('@/assets/photo/_S__3113.jpg')"
                alt=""
              />
            </div>
          </b-col>

          <b-col cols="12" md="6" class="featuers-block">
            <b-row
              class="features__content_wrapper"
              align-h="center"
              align-v="center"
            >
              <b-col cols="12" md="10" class="features-block_text-canvas">
                <h3 class="features-block_title">Наш профиль</h3>

                <p class="features-block_text">
                  С-МАТЕРИАЛС оказывает услуги по поставкам широкого спектра
                  товаров крупному российскому бизнесу.
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="right">
          <b-col cols="12" md="6" class="featuers-block">
            <b-row
              align-v="center"
              align-h="center"
              class="features__content_wrapper"
            >
              <b-col cols="12" md="10" class="features-block_text-canvas">
                <h3 class="features-block_title">Наши клиенты</h3>

                <p class="features-block_text">
                  Нашими клиентами являются «Норильский никель», «Роснефть»,
                  «РН-Ванкор», «РН-Юганскнефтегаз» и другие компании. С каждым
                  заказчиком мы выстраиваем доверительные и длительные
                  отношения. Как следствие, объем спроса на поставки товаров и
                  услуг непрерывно растет.
                </p>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12" md="6">
            <div class="features_img-canvas">
              <img
                class="features_img"
                :src="require('@/assets/img/partner/teams.jpg')"
                alt=""
              />
            </div>
          </b-col>
        </b-row>

        <b-row class="last">
          <b-col cols="12" md="6">
            <div class="features_img-canvas">
              <img
                class="features_img"
                :src="require('@/assets/img/partner/openspace.jpg')"
                alt=""
              />
            </div>
          </b-col>

          <b-col cols="12" md="6" class="featuers-block">
            <b-row
              align-h="center"
              align-v="center"
              class="features__content_wrapper"
            >
              <b-col cols="12" md="10" class="features-block_text-canvas">
                <h3 class="features-block_title">Партнерская сеть</h3>

                <p class="features-block_text">
                  Чтобы обеспечить рынок качественными товарами и услугами, мы
                  создали партнерскую сеть поставщиков и производителей.
                  Представители малого и среднего бизнеса нацелены на рост и
                  регулярно наращивают свои объемы продаж, используя ресурсы
                  рынка тендерных поставок и возможности С-МАТЕРИАЛС.
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <Deliveries />

    <!-- <Specialize
      img1="img/partner/hands.jpg"
      title1="Тендерное сопровождение"
      button1="Список услуг"
      @button1Emit="ServicesPartner"
      text1="Мы помогаем компаниям заключать успешные сделки на рынке тендеров, оказывая сопровождение на всех этапах от получения ЭЦП до заключения контракта и выстраивания отдела тендерных продаж. Предоставляем полный комплекс услуг, чтобы продажи в тендерах стали регулярными и прогнозируемыми."
      img2="img/partner/write.jpg"
      title2="Тендерное снабжение"
      button2="Список услуг"
      @button2Emit="ServicesPartner"
      text2="Предоставляем компаниям малого и среднего бизнеса возможность стать поставщиками товаров и услуг для наших крупных заказчиков Федерального уровня. У крупного Российского бизнеса свои стандарты и требования."
    /> -->

    <PartnerList
      :title="partnerList.title"
      :description="partnerList.description"
      :top="partnerList.top"
      :bottom="partnerList.bottom"
      :modal="partnerList.modal"
    />

    <LineInfo title="Ценность для каждого партнера" />

    <PartnerProfits />

    <LineInfo
      title="Для кого мы открыты к сотрудничеству?"
      description="Узнайте подробнее о процессе найма и всех открытых вакансиях."
    />

    <div class="open">
      <b-container>
        <b-row>
          <b-col cols="12" md="6">
            <div class="open__card">
              <b-row align-h="center">
                <div class="open__img-wrapper">
                  <img
                    class="open__img"
                    :src="require('@/assets/svg/icon-like.svg')"
                    alt=""
                  />
                </div>
              </b-row>

              <div class="open__text_wrapper">
                <h3 class="open__title">Работаем</h3>

                <p class="open__text">
                  Мы открыты для каждой компании, нацеленной на рост, развитие и
                  долгосрочные отношения. Готовы помочь новичкам стать успешными
                  участниками аукционов.
                </p>
              </div>
            </div>
          </b-col>

          <b-col cols="12" md="6">
            <div class="open__card">
              <b-row align-h="center">
                <div class="open__img-wrapper">
                  <img
                    class="open__img"
                    :src="require('@/assets/svg/icon-dislike.svg')"
                    alt=""
                  />
                </div>
              </b-row>

              <div class="open__text_wrapper">
                <h3 class="open__title">Не работаем</h3>

                <p class="open__text">
                  Не работаем с компаниями, которые ищут сиюминутную выгоду,
                  пренебрегая отношениями с клиентами. Мы работаем на результат,
                  и самым важным показателем для нас является польза, которую мы
                  приносим партнёрам.
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <LineInfo
      title="Партнерская сеть из 14 компаний"
      description="Узнайте подробнее о процессе найма и всех открытых вакансиях."
    />

    <!-- Partner Lists -->
    <div class="partner_web container">
      <div class="partner_web__text_wrapper row">
        <b-col cols="12" md="4">
          <p class="pertner_web__text">ООО «СибТехноСтрой»</p>
          <p class="pertner_web__text">ООО НОВАТЕХ</p>
          <p class="pertner_web__text">ООО "ПСК ОРТА"</p>
        </b-col>

        <b-col cols="12" md="4">
          <p class="pertner_web__text">ООО "ВИДЕОСЕРВИС"</p>
          <p class="pertner_web__text">ООО Экосистема</p>
          <p class="pertner_web__text">ООО "КЗСК"</p>
        </b-col>

        <b-col cols="12" md="4">
          <p class="pertner_web__text">ООО «РИО КАР»</p>
          <p class="pertner_web__text">ООО ГК «Проф-Система»</p>
          <p class="pertner_web__text">ООО "АРТСТРОЙ"</p>
        </b-col>
      </div>

      <b-row align-h="center">
        <b-col cols="12" md="6">
          <p class="text-center">
            Посмотреть весь список партнеров, категорий товаров и услуг, которые
            они предоставляют.
          </p>
        </b-col>
      </b-row>

      <b-row align-h="center">
        <button
          @click="$router.push({ path: '/partner_list' })"
          id="button_outline"
        >
          Список партнеров
        </button>
      </b-row>
    </div>

    <!-- <PartnerCases /> -->

    <PartnerForm />

    <!-- <News /> -->
  </div>
</template>

<script>
import Deliveries from "@/components/Deliveries.vue";
import LineInfo from "@/components/LineInfo.vue";
import PartnerForm from "@/components/PartnerForm.vue";
import News from "@/components/News.vue";
import PartnerList from "@/components/PartnerList.vue";
import WelcomeHome from "@/components/WelcomeHome.vue";
import PartnerProfits from "@/components/PartnerProfits.vue";
import PartnerCases from "@/components/PartnerCases.vue";

export default {
  components: {
    Deliveries,
    LineInfo,
    PartnerForm,
    News,
    PartnerList,
    WelcomeHome,
    PartnerProfits,
    PartnerCases,
  },
  data() {
    return {
      partnerList: {
        title: "Два вида сотрудничества",
        description: "Держим в курсе актуальных новостей",
        top: {
          img: "photo/_S__3306.jpg",
          title: "Тендерное сопровождение",
          text: `
          Мы помогаем компаниям заключать успешные сделки на рынке
          тендеров, оказывая сопровождение на всех этапах от
          получения ЭЦП до заключения контракта и выстраивания
          отдела тендерных продаж. Предоставляем полный комплекс
          услуг, чтобы продажи в тендерах стали регулярными и
          прогнозируемыми.
          `,
        },
        bottom: {
          img: "photo/_S__3108.jpg",
          title: "Тендерное снабжение",
          text: `
          У крупного российского бизнеса свои стандарты и требования, не каждая компания может самостоятельно стать поставщиком. Мы предоставляем компаниям малого и среднего бизнеса возможность стать поставщиками товаров и услуг для наших крупных заказчиков федерального уровня. 
          `,
        },
        modal: [
          `
            <b>Топовые услуги, гарантирующие рост:</b> <br />
            1. Выстраивание стратегии тендерных продаж, минимизация рисков. <br />
            2. Построение отдела тендерных продаж: наём персонала, обучение, выстраивание и автоматизация бизнес-процессов.<br />
            <br />
            <b>10 базовых услуг: </b><br />
            1. Выпуск ЭЦП.<br />
            2. Аккредитация на электронной площадке.<br />
            3. Регистрация в Единой информационной системе<br />
            4. Глубокая аналитика рынка: объем рынка, действия конкурентов, изучение цен, и др.<br />
            5. Поиск конкурсов в соответствии со стратегией развития компании на рынке тендеров.<br />
            6. Подготовка документов и подача заявки на участие в тендере от имени компании.<br />
            7. Участие в электронных торгах от имени компании.<br />
            8. Выпуск банковской гарантии, необходимой для участия в закупке или при подписании контракта.<br />
            9. Помощь в подписании контракта. <br />
            10. Консультационные услуги по всем вопросам, связанным с тендерными поставками.

            `,

          `
            Мы умеем работать с крупным бизнесом и даем возможность небольшим компаниям становиться нашими субподрядчиками. Для наших партнёров это возможность стремительного роста без прямого участия в тендерах.
            `,
        ],
      },

      slides: [
        {
          img: "home/director2.jpg",
          title: "Экосистема роста",
          text: `
          Мы создаём экосистему, в которой рост продаж и объёмов производства становится закономерностью для наших партнёров. Команда С-МАТЕРИАЛС убеждена: в бизнесе каждый должен заниматься тем, в чём он профессионален. Поэтому мы готовы помочь компаниям быстро и эффективно выйти на рынок тендеров. Наши специалисты берут на себя все рутинные процессы, а у партнёров появляется возможность качественно заниматься собственными профильными задачами. 
          `,
          sign: "Константин Миронов.",
        },
      ],
    };
  },
  methods: {
    ServicesPartner() {
      this.$router.push({ name: "Partner2" });
    },
  },
};
</script>

<style lang="scss">
// applicant-header
.applicant-header {
  position: relative;
  overflow: hidden;
  margin-bottom: 100px;
}
.applicant-header_bg {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.applicant-header_bg-filter {
  background-color: rgba($black, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 2;
}

.applicant-header_content {
  position: relative;
  height: 100vh;
  z-index: 3;
}

.applicant-header_title {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 30px;
}
.partner_title_page {
  text-transform: uppercase;
}

// ac
.ac-welcome {
  position: relative;
  padding-bottom: 100px;
}
.rictangle__ac {
  top: 80%;
  left: 100%;

  z-index: 1;
  position: absolute;
  height: 140vh;

  transform: translate(-50%, -50%);
  overflow: hidden;
}

.ac_wrapper {
  height: 500px;
  width: 100%;

  position: relative;
  overflow: hidden;

  border-radius: 0 20px 140px 0;
}
.ac_img {
  position: absolute;

  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ac-welcome_content {
  padding-left: 30px;
  padding-right: 30px;
  z-index: 4;
  position: relative;
}
.ac-welcome_content__title {
  font-size: 28px;
  font-weight: 600;
  color: $accent;
  padding-bottom: 30px;
  margin: 0;
}
.ac-welcome_content__text {
  font-size: 16px;
  color: $white;
  margin: 0;
}

// features
.features {
  position: relative;
  z-index: 2;
  margin-bottom: 100px;
  background-color: $gray;
}
.left .features_img-canvas {
  height: 320px;
  position: relative;
  overflow: hidden;
  border-radius: 0 5px 120px 0;
}
.right .features_img-canvas {
  height: 320px;
  position: relative;
  overflow: hidden;
  border-radius: 5px 0 0 120px;
}
.last .features_img-canvas {
  height: 320px;
  position: relative;
  overflow: hidden;
  border-radius: 0 120px 5px 0;
}
.features_img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
}
.features-block {
  background-color: $gray;
}
.features-block_text-canvas {
  padding-left: 30px;
  padding-right: 30px;
}
.features-block_title {
  color: $accent;
  font-size: 20px;
  font-weight: 700;
}
.features-block_text {
  color: $white;
  font-size: 16px;
  font-weight: 400;
}

// open
.open {
  padding-bottom: 100px;
}
.open__card {
  margin-bottom: 30px;
  padding: 60px;
  background-color: $gray;
  min-height: 470px;
  border-radius: 10px;
}
.open__img-wrapper {
  height: 100px;
  width: 100px;
  position: relative;
  overflow: hidden;

  margin-bottom: 30px;
}
.open__img {
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: contain;
}

.open__title {
  text-align: center;
}
.open__text {
  text-align: center;
}

.partner_web {
  padding-bottom: 100px;
}
.partner_web__text_wrapper {
  padding-bottom: 60px;
}
.pertner_web__text {
  font-size: 22px;
  color: $white;
}

.features__content_wrapper {
  height: 100%;
}
</style>
